<template>
  <v-btn
    v-scroll="onScroll"
    v-show="isToTop"
    color="primary"
    dark
    fab
    fixed
    bottom
    right
    small
    class="to-top"
    @click="moveToTop()"
  >
    <v-icon>mdi-chevron-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  computed: {
    isToTop() {
      return this.$store.state.layout.isToTop
    }
  },
  methods: {
    // 스크롤 시
    onScroll(e) {
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.$store.state.layout.isToTop = top > 40
    },
    // 맨 위로 이동
    moveToTop() {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.to-top {
  margin-bottom: 120px;
}
</style>
